/* eslint-env browser */
import { CountUp } from './library/countUp.min';

let isActiveAnimationCountUp = false;

function _startStatisticsAnimation() {
  const DEFAULT_OPTIONS_STATISTIC = {
    duration: 2,
    useGrouping: false,
    suffix: '+',
  };

  const Statistics = {
    business: {
      elementID: 'lbl_business',
      toValue: 2000,
    },
    universities: {
      elementID: 'lbl_universities',
      toValue: 30,
    },
    hospital: {
      elementID: 'lbl_hospital',
      toValue: 20,
    },
    schools: {
      elementID: 'lbl_schools',
      toValue: 60,
    },
    goverment: {
      elementID: 'lbl_goverment',
      toValue: 30,
    },
  };

  Object.keys(Statistics).forEach((key) => {
    const countUp = new CountUp(
      Statistics[key].elementID,
      Statistics[key].toValue,
      DEFAULT_OPTIONS_STATISTIC,
    );
    if (!countUp.error) {
      countUp.start();
    }
  });
}

function _navbarScroll(scrollY) {
  const navbar = document.getElementById('navbar');
  if (navbar) {
    if (scrollY >= (navbar.offsetHeight - 35)) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  }
}

function _statisticsScroll(scrollY) {
  const customers = document.getElementById('customers');
  const statistics = document.getElementById('statistics');

  if (customers && statistics) {
    const positionCustomerCountUp = (customers.offsetTop - scrollY) - (customers.offsetHeight * 2);

    if (positionCustomerCountUp >= statistics.offsetHeight) {
      isActiveAnimationCountUp = false;
    }

    if (positionCustomerCountUp < statistics.offsetHeight && !isActiveAnimationCountUp) {
      _startStatisticsAnimation();
      isActiveAnimationCountUp = true;
    }
  }
}

window.addEventListener('load', () => {
  if (window.performance) {
    const timeSincePageLoad = Math.round(performance.now());
    ga('send', 'timing', 'JS Dependencies', 'load', timeSincePageLoad);
  }

  window.setTimeout(() => {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('hidden__scroll')) {
      body.classList.remove('hidden__scroll');
      body.removeAttribute('class');
    }

    const preloader = document.getElementsByClassName('preloader')[0];
    if (preloader.classList.contains('active')) {
      preloader.classList.remove('active');
    }

    _startStatisticsAnimation();
  }, 450);

  const swiper = document.getElementById('swiper');
  if (swiper) {
    new Swiper('#swiper', {
      spaceBetween: 30,
      direction: 'horizontal',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
      },
    });
  }

  const navbarHeight = document.getElementById('navbar').offsetHeight;
  if (navbarHeight) {
    const bannerElement = document.getElementById('banner');
    if (bannerElement){ 
      bannerElement.style.marginTop = `${navbarHeight}px`;
    }
  }
  
  const year = document.getElementById('year');
  if(year){
    const dateCurrent = new Date();
    year.innerText = dateCurrent.getFullYear();
  }
});

window.onscroll = () => {
  const scrollY = window.pageYOffset || document.documentElement.scrollTop;
  _navbarScroll(scrollY);
  _statisticsScroll(scrollY);
};

const menuButton = document.getElementById('button-hamburger');
menuButton.addEventListener('click', () => {
  const status = menuButton.classList.contains('actived') ? 'Hide' : 'Active';
  ga('send', 'event', 'Menu navbar', 'Click', `${status} menu mobile`);

  menuButton.classList.toggle('actived');
  const body = document.getElementsByTagName('body')[0];
  body.classList.toggle('hidden__scroll');
  setTimeout(() => {
    const sidebar = document.getElementById('side-navbar');
    sidebar.classList.toggle('open');
  }, 50);
});
